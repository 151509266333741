.image-custom {
  width: 20%;
  height: auto;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  margin: o, auto;
  text-align: center;
}

.img-container-custom {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}
